.hero {
  background-color: #1b1530;
  color: #ffffff;
  text-align: center;
  position: relative;
}

.hero-content {
  /* max-width: 800px; */
  /* width: 50%; */
  margin: 0 auto;
  background-color: #1b1530;
  /* position: relative; */
  /* z-index: 1; */
}

.hero h1, .hero p {
  font-size: 2rem;
  margin-bottom: 20px;
  /* padding: 20px;  */
  border-radius: 10px;
}

.hero p {
  font-size: 1.2rem;
}

.hero button {
  font-size: 1rem;
  padding: 15px 30px;
  margin-top: 1.4rem;
  background-color: #ff7f50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero button:hover {
  background-color: #ff6347;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .hero {
    padding: 60px 20px; /* Adjusted padding for mobile */
  }
 

  .hero h1, .hero p {
    font-size: 1.5em; /* Adjusted font size for mobile */
  }
}

@media screen and (max-width: 425px) {
  .hero h1, .hero p {
    font-size: 1rem; /* Adjusted font size for mobile */
  }
}
