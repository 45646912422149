/* Form container */
.form-wrapper {
  background-color: #1b1530;
  padding-top: 10px;
  padding-bottom: 10px;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
}

#Form {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
}

form {
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}

/* Form labels */
label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff; /* Changed label color to white for contrast */
}

/* Input fields */
input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ffffff; /* Changed border color to white for contrast */
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff; /* Changed input text color to white for contrast */
  background-color: #1b1530; /* Changed background color to match form container */
}

/* Checkbox labels */
input[type="checkbox"] + label {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  color: #ffffff; /* Changed checkbox label color to white for contrast */
}

/* Submit button */
button[type="submit"] {
  background-color: #ff7f50;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 auto;
  display: block;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Thank you message (optional) */
.thank-you-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  form {
    max-width: 100%;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    max-width: 500px; /* Adjusted max-width for better fit */
  }
}

@media screen and (max-width: 425px) {
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    max-width: 300px; /* Adjusted max-width for smaller screens */
  }
}
