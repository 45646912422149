.carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.slide-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slide {
  display: none;
}

.active {
  display: block;
}

.slide img {
  width: 100%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
