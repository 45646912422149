/* Notification.css */

/* Custom styles for the toast notification */
.notification {
    font-family: Arial, sans-serif;
    background-color: #4caf50; /* Green background color */
    color: #fff; /* White text color */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Padding around the content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle effect */
    max-width: 300px; /* Maximum width of the notification */
    overflow-wrap: break-word; /* Wrap long text */
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    /* Adjust styles for smaller screens here */
    .notification {
      max-width: 90%; /* Reduce maximum width for smaller screens */
    }
  }
  