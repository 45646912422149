/* TrustBadge.css */

/* Container for trust badges */
.trust-badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  /* Individual trust badge */
  .trust-badge {
    max-width: 120px; /* Adjust based on your badge size */
    margin: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    transition: transform 0.2s ease; /* Smooth hover effect */
  }
  
  .trust-badge:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  /* Centered text below each badge */
  .trust-badge-label {
    text-align: center;
    font-size: 14px;
    color: #555;
    margin-top: 8px;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .trust-badge-container {
      flex-direction: column;
      align-items: flex-start; /* Align badges to the left on smaller screens */
    }
    .trust-badge {
      max-width: 100%; /* Take full width on small screens */
    }
  }
  