.features {
  padding: 20px;
  text-align: center;
  background-color: #1b1530; /* Dark background color */
  color: #ffffff; /* White text color */
}

.feature {
  margin-bottom: 20px;
}

.feature img {
  height: 50px; /* Adjust as needed */
}

.feature h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1.2em;
}
