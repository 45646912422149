.social-proof {
  padding: 20px;
  text-align: center;
}

.testimonials {
  height: 20rem;
  text-align: center !important;
  position: relative;
  overflow: hidden; /* Hide overflow to prevent horizontal scrollbars */
}

.testimonial {
  display: none; /* Initially hide all testimonials */
  width: 100%; /* Each testimonial takes the full width */
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Box shadow for depth effect */
  opacity: 0;
  position: absolute; /* Position each testimonial absolutely */
  top: 0;
  left: 0;
  transition: opacity 0.5s ease; /* Transition for fade-in effect */
}

.testimonial.active {
  display: block; /* Display the active testimonial */
  opacity: 1;
  margin: 0 auto;
}

.testimonial img {
  width: 100px; /* Adjust image size based on your design */
  height: 100px;
  border-radius: 50%; /* Make the image round */
  margin-bottom: 10px;
}

.testimonial p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.6;
  color: #333; /* Dark text color */
}

.testimonial p.rating {
  margin-top: 10px;
  font-size: 14px;
  color: #666; /* Lighter text color */
}

/* Arrow buttons */
.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333; /* Dark color for arrows */
  cursor: pointer;
  transition: color 0.3s ease;
}

.prev:hover,
.next:hover {
  color: #555; /* Darker color on hover */
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
