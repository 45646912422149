.cta {
  padding: 20px;
  text-align: center;
  background-color: #1b1530; /* Dark background */
  color: #ffffff; /* White text color */
}

.cta-button {
  background-color: #ff7f50;
  border: none;
  color: #ffffff; /* White text color */
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45a049; /* Darker green */
}


.cta p { 
  font-size: 1.2em;
  color:orange
}