/* General styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* h1, h2, p {
    margin: 0 0 15px 0;
  } */
  
  button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  /* Hero shot */
  .hero {
    /* background-image: url('hero-shot.jpg'); */
    background-size: cover;
    height: 300px;
    color: white;
    text-align: center;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  /* Call to action */
  .cta {
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;
  }
  
  /* Value proposition */
  .value-prop {
    padding: 20px;
    text-align: center;
  }
  
  /* Features and benefits */
  .features-benefits {
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  .features-benefits ul {
    list-style-type: none;
    padding: 0;
  }
  
  /* Testimonials */
  .testimonials {
    padding: 20px;
    text-align: center;
  }
  
  .testimonials .testimonial {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .hero, .cta, .value-prop, .features-benefits, .testimonials {
      padding: 10px;
    }
  }
  