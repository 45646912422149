.header {
    background-color: #e2b42d; /* Dark background */
    padding: 20px;
    text-align: center;
  }
  
  .logo {
    height: 50px; /* Adjust as needed */
  }
  
  header p {
    font-size: 1.9rem;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .logo {
      height: 30px; /* Adjust as needed */
    }
  }
  

  