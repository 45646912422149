/* LimitedTimeOfferBanner.css */

.limited-time-offer-banner {
    background-color: #ff5a5f; /* Attention-grabbing red color */
    color: #ffffff; /* White text color */
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .expiry-timer {
    font-size: 18px;
    margin-top: 5px;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .limited-time-offer-banner {
      font-size: 14px; /* Smaller font size for mobile */
    }
  }
  