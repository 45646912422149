/* Bonus.css */

.bonus-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow bonus cards to wrap to the next line */
    justify-content: space-between;
    /* Distribute items evenly along the main axis */
    padding: 20px;
    /* Add padding to the container */
}

.bonus-card {
    flex: 0 0 calc(30% - 20px);
    /* Set width of each bonus card, considering margins */
    margin-bottom: 20px;
    /* Add some space between bonus cards */
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

/* Adjust margins and padding within the card as needed */
.bonus-card:not(:last-child) {
    margin-right: 20px;
    /* Add some space between bonus cards */
}

/* Rest of your existing CSS */

.bonus-header {
    margin-bottom: 20px;
}

.bonus-header h3 {
    font-size: 1.5em;
    color: #f63a01;
}

.bonus-image img {
    max-width: 100%;
    /* Ensure bonus images don't overflow on smaller screens */
    height: auto;
    margin-bottom: 20px;
}

.bonus-text p {
    font-size: 1.3em;
    color: #f09206;
}

.bonus-footer p {
    font-weight: bold;
    font-size: 1.9em;
    color: #45f406;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .bonus-container {
        flex-direction: column;
        /* Change flex direction to column on smaller screens */
    }
   
}